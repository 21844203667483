.hover-effect {
  transition: all 0.3s;
  position: relative;
}
.hover-effect.disabled {
  opacity: 0.5;
  transform: scale(0.98);
  cursor: default;
}
.hover-effect:hover:not(.disabled) {
  cursor: pointer;
  opacity: 0.8;
}
.hover-effect.scale:not(.disabled):hover {
  transform: scale(0.98);
}
.ml-6 {
  margin-left: 6px;
}
.mr-6 {
  margin-right: 6px;
}

/* .input-group.input-group-outline.is-focused .form-label,
.input-group.input-group-outline.is-filled .form-label {
  color: #1a73e8;
}

.input-group.input-group-outline.is-focused .form-label + .form-control,
.input-group.input-group-outline.is-filled .form-label + .form-control {
  border-color: #1a73e8 !important;
  border-top-color: transparent !important;
  box-shadow: inset 1px 0 #1a73e8, inset -1px 0 #1a73e8, inset 0 -1px #1a73e8;
} */
.is-invalid .invalid-feedback {
  display: block;
}
select {
  background-position: right 9px center !important;
}
body.rtl select {
  background-position: right 95% center !important;
}

.main-header {
  height: 350px;
  background-image: url("../public/content/bg.jpg");
  background-position: center;
  background-size: cover;
  /* background-image: url("../public/content/nakbah.svg");
  background-size: contain; */
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.main-header::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), #fff);
}

@media only screen and (max-width: 450px) {
  .main-header .header-info {
    flex-direction: column;
  }
  .main-header .header-info .btn {
    transform: scale(0.9);
  }
  .main-header svg {
    width: 200px;
  }
}
@media only screen and (max-width: 800px) {
  .main-header img[data-m-logo] {
    height: 35px !important;
  }
  .main-header img[data-h-logo] {
    height: 40px !important;
  }
  .madeby {
    margin-top: 3px;
    font-size: 0.7em !important;
    opacity: 0.8;
  }
}
@media only screen and (max-width: 380px) {
  .main-header img[data-m-logo],
  .main-header img[data-h-logo] {
    display: none;
  }
}

.form-label {
  z-index: 6 !important;
  pointer-events: none;
}

.ps {
  overflow: auto !important;
  overflow-anchor: none;
  touch-action: auto;
  /* -ms-overflow-style: none;
  -ms-touch-action: auto; */
}

body {
  min-height: 80vh;
}
.form-check {
  padding-left: 0;
  /* padding-left: calc(var(--bs-gutter-x) * 0.5); */
}

/* .input-group.input-group-outline.is-filled .form-label + .form-control,
.input-group.input-group-outline.is-focused .form-label + .form-control {
  border-color: #e91e63 !important;
  border-top-color: transparent !important;
  box-shadow: inset 1px 0 #e91e63, inset -1px 0 #e91e63, inset 0 -1px #e91e63;
} */

.modal-content {
  min-height: 300px;
}

td[role="cell"] {
  font-size: 0.85rem !important;
}

.img-preview {
  position: fixed;
  top: 0;
  bottom: 0;
  /* left: 0; */
  height: 99vh;
  /* right: 0; */
  z-index: 1000;
  display: flex;
  justify-content: center;
}
.img-preview img {
  margin-top: 10px;
}
.img-preview .cls {
  position: fixed;
  top: 20px;
}
::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
body.brtl .row.rtl .form-check .custom-control-label {
  margin-right: 6px;
}
body.brtl .f__m__h {
  text-align: end;
}
body.brtl .f__m__b__ask,
body.brtl .row.rtl,
body.brtl .jrtl {
  direction: rtl;
}
body.brtl .f__m__f {
  justify-content: flex-start;
}
body.brtl .btn-group {
  direction: ltr !important;
}
tr[data-details] table {
  table-layout: fixed;
}
tr[data-details] table th {
  text-align: right;
  padding-right: 10px;
}
tr[data-details] table td {
  text-align: left;
}
body.brtl input[type="date"] {
  text-align: right;
}

body.brtl .input-group.input-group-outline.is-invalid .form-control,
body.brtl .input-group.input-group-outline.is-valid .form-control {
  background-position: left 0.75rem center !important;
}

body.brtl select {
  background-position: left 0 center !important;
}
body:not(.brtl) .form-select {
  padding-left: 1rem !important;
}
table.same-width {
  table-layout: fixed;
  width: 100%;
}
.modal-dialog {
  transition: all 0.2ms;
}

.floting-buttons {
  position: fixed;
  top: 90%;
  left: 20px;
  display: flex;
  flex-direction: column;
  z-index: 9999999;
}

.floting-buttons .hover-effect {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
}
.floting-buttons .hover-effect + .hover-effect {
  margin-top: 6px;
}
.cls-1,
.cls-2 {
  filter: drop-shadow(-0px 0px 15px rgba(255, 255, 255, 0.9));
}
.cls-1 {
  /* fill: #3aaa35; */
  fill: var(--bs-primary);
}
.cls-2 {
  fill: #0c6fa5;
}
.side-bar .cls-1,
.side-bar .cls-2 {
  fill: #fff;
  filter: drop-shadow(-0px 0px 15px rgba(255, 255, 255, 0.2));
}

.modal-header .btn-close {
  padding: 0 !important;
  margin: 0 !important;
}

.f__cm {
  --f-f: var(--bs-body-font-family) !important;
}
.color-transparent {
  color: transparent;
}

/* لإخفاء الأيقونة */
.input-group:not(.is-focused)
  input[type="date"]::-webkit-calendar-picker-indicator {
  display: none; /* لإخفاء الأيقونة في متصفحات WebKit مثل Chrome و Safari */
}
.input-group:not(.is-focused) input[type="date"]::-webkit-inner-spin-button,
.input-group:not(.is-focused) input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* لإخفاء الأيقونة في متصفحات WebKit مثل Chrome و Safari */
  margin: 0; /* يخفي الأيقونة */
}
tr.green,
tr.red,
tr.orange {
  color: var(--bs-white);
}
tr.green {
  background: var(--bs-success);
}
tr.orange {
  background: var(--bs-warning);
}
tr.red {
  background: var(--bs-danger);
}
.table > :not(caption) > * > * {
  padding-top: 3px;
  padding-bottom: 3px;
}
